import Chef from "./chefAbi";
import {SwapPlatforms} from "@/config/swapPlatforms";

export const config = {
    name: 'PolyQuail',
    chef: {
        address: "0xeA038416Ed234593960704ddeD73B78f7D578AA0",
        rewardTokenTicker: "KWIL",
        abi: Chef,
        rewardTokenFunction: "kwil",
        pendingRewardsFunction: "pendingKWIL"
    },
    swap: SwapPlatforms.None
}

export default config;
